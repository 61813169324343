import lalirose from './lali_bg.jpg';
import './App.css';
import React, { useState, useMemo, useEffect } from 'react';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  getAuth,
  onAuthStateChanged,
  signInWithPhoneNumber,
  signOut,
} from 'firebase/auth';
import { firebaseConfig } from './authentication/config';
import { useNavigate } from 'react-router-dom';


// reCAPTCHA verification does not work with the Firebase emulator.
// Hence, we do not connect to the emulator in this example.

// We declare variables used on the window object
// We use a custom interface to avoid these modifying the global Window type in other files
interface CustomWindow extends Window {
  signingIn?: boolean;
  verifyingCode?: boolean;
  confirmationResult?: ConfirmationResult | null;
  recaptchaVerifier?: RecaptchaVerifier;
  recaptchaWidgetId?: number;
}

declare const window: CustomWindow;

// Comes from Google reCAPTCHA V3 script included in the HTML file
declare const grecaptcha: any;

/**
 * Returns true if the phone number is valid.
 */
function isAustralianPhoneNumberValid(phoneNumber: string) {
  const pattern = /^04[0-9]{8}$/;
  return phoneNumber.search(pattern) !== -1;
}
function isInternationalPhoneNumberValid(phoneNumber: string) {
  const pattern = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/;
  return phoneNumber.search(pattern) !== -1;
}

/**
 * Returns true if the ReCaptcha is in an OK state.
 */
function isCaptchaOK() {
  if (
    typeof grecaptcha !== 'undefined' &&
    typeof window.recaptchaWidgetId !== 'undefined'
  ) {
    const recaptchaResponse = grecaptcha.getResponse(window.recaptchaWidgetId);
    return recaptchaResponse !== '';
  }
  return false;
}

/**
 * Re-initializes the ReCaptacha widget.
 */
function resetReCaptcha() {
  if (
    typeof grecaptcha !== 'undefined' &&
    typeof window.recaptchaWidgetId !== 'undefined'
  ) {
    grecaptcha.reset(window.recaptchaWidgetId);
  }
}

function PhoneAuthentication() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);

  const [token, setToken] = useState("");
  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        console.log("User is signed in");
        setUser(user);
        
        navigate("/dashboard", { relative: "route" });
      } else {
        // User is signed out
        console.log("User is signed out");
        setUser(null);
      }
      setAuthLoading(false); // Set loading to false once the auth state is determined
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const [codeInput, setCodeInput] = useState("");
  const [phoneNumberInput, setPhoneNumberInput] = useState("");
  const [showSignInForm, setShowSignInForm] = useState(true);
  const [signInButtonDisabled, setSignInButtonDisabled] = useState(true);
  const [verifyCodeButtonDisabled, setVerifyCodeButtonDisabled] = useState(true);
  const [captchaOK, setCaptchaOK] = useState(false);

  const [signingIn, setSigningIn] = useState(false);
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);

  useEffect(() => {
    const initializeRecaptcha = async () => {
      try {
        const verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
          size: 'normal',
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            setCaptchaOK(true);
            setSignInButtonDisabled(false);
          },
          'expired-callback': () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            setCaptchaOK(false);
            setSignInButtonDisabled(true);
          },
        });

        const verification = await verifier.verify();
        setRecaptchaVerifier(verifier);
        setToken(verification);
      } catch (error) {
        console.error('Error initializing reCAPTCHA:', error);
      }
    };

    initializeRecaptcha();
  }, [auth]); 


  // window.recaptchaVerifier.render().then(function (widgetId) {
  //   window.recaptchaWidgetId = widgetId;
  // });

  useEffect(() => {
    if (!(auth.currentUser || confirmationResult)) {
      // resetReCaptcha();
      setShowSignInForm(true);
    }
  }, [auth.currentUser, confirmationResult]);

  useEffect(() => {
    if (captchaOK === false || (isAustralianPhoneNumberValid(phoneNumberInput) === false && isInternationalPhoneNumberValid(phoneNumberInput) === false) || signingIn === true) {
      setSignInButtonDisabled(true);
    } else {
      setSignInButtonDisabled(false);
    }
  }, [grecaptcha, window.recaptchaWidgetId, signingIn, phoneNumberInput]);

  useEffect(() => {
    if (verifyingCode === true || codeInput === "") {
      setVerifyCodeButtonDisabled(true);
    } else {
      setVerifyCodeButtonDisabled(false);
    }
  }, [verifyingCode, codeInput]);

  const navigate = useNavigate();

  return (
    <div className="App">
      <div>
        <button
          className="mdl-button mdl-js-button mdl-button--raised"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          {"< Back"}
        </button>
      </div>
      <header className="App-header">
        <h2>
          RSVP
        </h2>
        <h1>
          Lusiana and Paul
        </h1>
      </header>
      {/* <div className="App-body">
        <img
          className={"main-photo"}
          src={lalirose}
          alt="lali rose"
        />
      </div> */}
      <div className="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-grid">
          <div
            className="mdl-card mdl-shadow--2dp mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-desktop"
          >
            <div
              className="mdl-card__title mdl-color--light-blue-600 mdl-color-text--white"
            >
              <h2 className="mdl-card__title-text">
                Phone number authentication with visible ReCaptcha
              </h2>
            </div>
            <div className="mdl-card__supporting-text mdl-color-text--grey-600">
              <p>Sign in with your phone number below.</p>

              {showSignInForm &&
                <form id="sign-in-form" onSubmit={(e) => {
                  e.preventDefault();
                  if (captchaOK && (isAustralianPhoneNumberValid(phoneNumberInput) || isInternationalPhoneNumberValid(phoneNumberInput))) {
                    console.log("Signing in!");

                    setSigningIn(true);
                    const appVerifier = recaptchaVerifier;
                    const signInNumber = isAustralianPhoneNumberValid(phoneNumberInput) ? phoneNumberInput.replace("0", "+61") : phoneNumberInput;
                    signInWithPhoneNumber(auth, signInNumber, appVerifier!)
                      .then(function (result) {
                        // SMS sent. Prompt user to type the code from the message, then sign the
                        // user in with confirmationResult.confirm(code).
                        setVerifyCodeButtonDisabled(false);
                        setConfirmationResult(result);
                        setSigningIn(false);
                      })
                      .catch(function (error) {
                        // Error; SMS not sent
                        console.error('Error during signInWithPhoneNumber', error);
                        console.log("Error during signInWithPhoneNumber");
                        console.log(error);
                        window.alert(
                          'Error during signInWithPhoneNumber:\n\n' +
                            error.code +
                            '\n\n' +
                            error.message,
                        );
                        setSigningIn(false);
                      });
                  } else {
                    console.log("Something didnt work");
                  }
                }}>
                  <div
                    className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label"
                    style={{ display: "flex", flexDirection: "column", gap: "6px" }}
                  >
                    <label className="mdl-textfield__label" htmlFor="phone-number">Enter your phone number:</label>
                    <input
                      className="mdl-textfield__input"
                      type="text"
                      id="phone-number"
                      value={phoneNumberInput}
                      onChange={(e) => setPhoneNumberInput(e.target.value)}
                    />
                    {(isAustralianPhoneNumberValid(phoneNumberInput) === false && isInternationalPhoneNumberValid(phoneNumberInput) === false) &&
                      <span className="mdl-textfield__error" style={{ color: "red" }}>Input is not an Australian mobile or international phone number. eg. 0411223344 or +61411223344</span>
                    }
                  </div>

                  <div id="recaptcha-container"></div>

                  <input
                    type="submit"
                    disabled={signInButtonDisabled}
                    className="mdl-button mdl-js-button mdl-button--raised"
                    id="sign-in-button"
                    value="Sign-in"
                  />
                </form>
              }
              {auth.currentUser &&
                <button
                  className="mdl-button mdl-js-button mdl-button--raised"
                  id="sign-out-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setSignInButtonDisabled(true);
                    setVerifyCodeButtonDisabled(true);
                    signOut(auth);
                  }}
                >
                  Sign-out
                </button>
              }

              <form id="verification-code-form" onSubmit={(e) => {
                e.preventDefault();
                  console.log("verifying code");
                  if (codeInput !== "") {
                    setVerifyingCode(true);
                    confirmationResult!.confirm(codeInput)
                      .then(function (result) {
                        // User signed in successfully.
                        const user = result.user;
                        console.log("user has signed in");
                        console.log(auth.currentUser);
                        setVerifyingCode(false);
                        setConfirmationResult(null);
                      })
                      .catch(function (error) {
                        // User couldn't sign in (bad verification code?)
                        console.log("user couldnt sign in");
                        console.error('Error while checking the verification code', error);
                        window.alert(
                          'Error while checking the verification code:\n\n' +
                            error.code +
                            '\n\n' +
                            error.message,
                        );
                        setVerifyingCode(false);
                      });
                  }
              }}>
                <div
                  className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label"
                  style={{ display: "flex", flexDirection: "column", gap: "6px" }}
                >
                  <label className="mdl-textfield__label" htmlFor="verification-code">
                    Enter the verification code:
                  </label>
                  <input
                    className="mdl-textfield__input"
                    type="text"
                    id="verification-code"
                    disabled={verifyCodeButtonDisabled}
                    value={codeInput}
                    onChange={(e) => setCodeInput(e.target.value)}
                  />
                </div>

                <input
                  type="submit"
                  className="mdl-button mdl-js-button mdl-button--raised"
                  id="verify-code-button"
                  value="Verify Code"
                />
                <button
                  className="mdl-button mdl-js-button mdl-button--raised"
                  id="cancel-verify-code-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmationResult(null);
                  }}
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>

          <div
            className="mdl-card mdl-shadow--2dp mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-desktop"
          >
            <div
              className="mdl-card__title mdl-color--light-blue-600 mdl-color-text--white"
            >
              <h2 className="mdl-card__title-text">User sign-in status</h2>
            </div>
            <div className="mdl-card__supporting-text mdl-color-text--grey-600">
              <div className="user-details-container">
                Firebase sign-in status:
                {authLoading === true ?
                  "Auth Loading"
                :
                  <span id="sign-in-status">{auth.currentUser?.uid ? "Signed-in" : "Signed-out"}</span>
                }
                {auth.currentUser?.phoneNumber &&
                  <div>Firebase auth <code>currentUser</code> {auth.currentUser?.phoneNumber}</div>
                }
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default PhoneAuthentication;


